import React from 'react';
import './index.scss';
import { t } from 'localization';
import { Icon } from '@just-ai/just-ui/dist';
import localize from '../../localization';
import { footerLocalization } from './localization/footer.loc';
import { getTosAndPpLinksFromOptions } from '../../pipes/functions';
import { useAppContext } from '../AppContext';

localize.addTranslations(footerLocalization);

const Footer = () => {
  const {
    // @ts-ignore
    appConfig: { domains, euroInstance },
    currentUser,
  } = useAppContext();

  const { termsOfUseUrl, privacyPolicyUrl } = getTosAndPpLinksFromOptions(
    currentUser?.userData.countryIsoCode || '',
    domains
  );

  return (
    <div className='footer'>
      <div className='footer_copyright'>
        <small>{t(euroInstance ? 'Footer: copyrightUsd' : 'Footer: copyright', new Date().getFullYear())}</small>
      </div>
      <div className='footer_links'>
        <a href={termsOfUseUrl} target='_blank' rel='noopener noreferrer'>
          <span>{t('Footer: ToS link text')}</span>
          <Icon name='falExternalLink' color='primary' size='1x' />
        </a>{' '}
        <a href={privacyPolicyUrl} target='_blank' rel='noopener noreferrer'>
          <span>{t('Footer: policy link text')}</span>
          <Icon name='falExternalLink' color='primary' size='1x' />
        </a>
      </div>
    </div>
  );
};

export default Footer;
